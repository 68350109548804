@font-face {
  font-family: "Heebo-Regular";
  src: local("Heebo-Regular"),
    url(../assets//fonts///heebo/Heebo-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto-Regular";
  src: local("Roboto-Regular"),
    url(../assets//fonts/roboto-slab/RobotoSlab-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Lato-Regular";
  src: local("Lato-Regular"),
    url(../assets//fonts/lato/Lato-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Raleway";
  src: local("Raleway"),
    url(../assets//fonts/raleway/Raleway-Light.ttf) format("truetype");
}

/* For logo */
@font-face {
  font-family: "Fonarto";
  src: local("Fonarto"),
    url(../assets//fonts/fonarto/Fonarto.ttf) format("truetype");
}
/* 
@font-face {
  font-family: "Fonarto";
  src: local("Fonarto"),
    url(./assets//fonts/recharge/recharge\ bd.ttf) format("truetype");
} */

/* .logo {
  background-image: url(../assets/images/coswork-logo-white.svg);
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-size: contain;
  position: relative;
} */

.logo {
  font-family: "Fonarto";
  font-size: 30px;
  font-weight: bold;
  background-image: linear-gradient(
    to right,
    rgba(104, 204, 152, 1) 41%,
    rgb(255, 139, 0) 10%
  );
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: transparent;
}

.logo-small {
  font-family: "Fonarto";
  font-weight: bold;
  color: white;
}

.logo-small-dark {
  font-family: "Fonarto";
  font-weight: bold;
  color: #010606;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Raleway", "Roboto-Regular", sans-serif;
}
